<template>
 <div class="col mt-3">
  <div class="card">
    <div class="card-body row">
      <div class="col-md-3 mt-2">
         <input type="text" class="form-control" :value="description" @keyup="(event)=> description=event.target.value" placeholder="description"/>
      </div>
      <div class="col-md-3 mt-2">
        <input type="date" class="form-control" v-model="date_submitted" placeholder="description"/>
      </div>
      <div class="col-md-2 mt-2">
        <LoadingButton class-name="btn btn-primary" icon-class="fa fa-search" :loading="waitingData" @on-click="update_reviews_search_params"></LoadingButton>
      </div>
      <div class="col-md-12">
        <div class="table-responsive py-4">
          <table  aria-describedby="table" class="table" id="product_reviews">
            <thead class="bg-primary text-white">
            <tr>
              <th style="width:5%">#</th>
              <th style="width:10%">Date</th>
              <th>Review</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
              <tr v-for="(review) in product_reviews" :key="review.id">
                <td>{{review.id}}</td>
                <td >{{$date_format.date_only(review.doneAt)}}</td>
                <td>
                  <textarea rows="5" class="form-control"  readonly v-model="review.description">
                  </textarea>
                </td>
                <td>
                  <button class="btn btn-sm btn-danger" @click="triggerDelete(review.id)">
                    <span class="fa fa-trash"></span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <PaginatedSearch @on-search="get_reviews" :search_params="reviews_search_params" :url="review_search_url"/>
        </div>
      </div>
    </div>
  </div>
 </div>
</template>

<script>


import PaginatedSearch from "@/components/PaginatedSearch";
import LoadingButton from "@/components/Buttons/LoadingButton";
import {alert} from "@/utils/alertUtils";
import axios from "axios";
export default {
  name: "AdminProductReviewManager",
  components:{
    LoadingButton,
    PaginatedSearch,
  },
  props:['product_id'],
  data(){
    return{
      review_search_url:`${process.env.VUE_APP_BACKEND_URL}/api/reviews/search/`,
      description:"",
      date_submitted:"",
      page:1,
      backend_url: process.env.VUE_APP_BACKEND_URL,
      waitingData:false,
      reviews_search_params:[
        {
          'name':'reference_id',
          'value':this.product_id,
        },
        {
          'name':'reference_name',
          'value':'product',
        },
        {
          'name':'description',
          'value':this.description,
        },
        {
          'name':'date',
          'value':this.date_submited,
        },
      ],
      product_reviews:[]
    }
  },
  methods:{
    update_reviews_search_params:async function () {
      this.waitingData=true
      let temp=this.reviews_search_params
      await temp.forEach((el,index)=>{
        if(el.name==="description"){
          temp[index]={name:'description',value:this.description}
        }
        if(el.name==="date"){
          temp[index]={name:'date',value:this.date_submitted}
        }
      })
      this.reviews_search_params=temp
    },
    get_reviews:function (evt){
      this.product_reviews=evt
       this.waitingData=false
    },
    triggerDelete:function (id) {
      alert.promptDelete(this.deleteReview,id)
    },
    deleteReview(id){
      axios
          .delete(
              `${process.env.VUE_APP_BACKEND_URL}/api/reviews/delete/${id}/`,
              {
                headers: {
                  Authorization: `Bearer ${this.$store.getters.getToken}`,
                },
              }
          )
          .then(() => {
            alert.success("Review deleted successfully")
            this.update_reviews_search_params()
          })
          .catch((err) => {
            if(err.response.status===403 || err.response.status===401){
              alert.error("Session Expired, Please Login again");
              this.$store.dispatch("logout");
            }else{
              alert.error("Error occured try,again")
            }
          });
    }
  },


}
</script>

<style scoped>

</style>