<template>

 <div class="card shadow-lg w-100 border-0 mb-0">
   <div class="card-body px-lg-5 py-lg-3">
     <div class="row">
       <div class="col-md-12">
         <h3>Actions</h3>
         <hr class="my-0 mb-3" />
       </div>
     </div>
     <div class="row">
       <div class="col-md-12">
          <span class="mt-3" v-if="$guards.is_super_admin() || $guards.is_Admin() || $guards.has_role('admin') ">
              <label class="form-control-label">Is Special Product</label>
              <div class="col-md-4">
                <label class="custom-toggle text-white">
                  <input
                      id="change_status"
                      type="checkbox"
                      :checked="product.is_special"
                      @change="changeStatusFn"
                  />
                  <span
                      class="custom-toggle-slider rounded-circle bg-default"
                      data-label-off="No"
                      data-label-on="Yes"
                  ></span>
                </label>
              </div>
          </span>
          <span class="mt-3" v-if="!$guards.is_super_admin() && !$guards.is_Admin()">
              <label class="form-control-label">Published</label>
              <div class="col-md-4">
                <label class="custom-toggle text-white">
                  <input
                      id="change_visibility"
                      type="checkbox"
                      :checked="product.visibilityStatus"
                      @change="changeFn"
                  />
                  <span
                      class="custom-toggle-slider rounded-circle bg-default"
                      data-label-off="No"
                      data-label-on="Yes"
                  ></span>
                </label>
              </div>
          </span>
       </div>

       <div class="col-md-12">
         <button
             v-if="$route.name !== 'Product Gallery' && !$guards.is_super_admin() && !$guards.is_Admin()"
             @click="$router.push({ name: 'Product Gallery', params:product.uuid });"
             class="btn btn-primary btn-block btn-sm  mr-2"
         >
           Manage Gallery
         </button>
       </div>
       <div class="col-md-12">
         <button
             v-if="!$guards.is_super_admin() && !$guards.is_Admin()"
             @click="$router.push({ name: 'Update Product', params: product.uuid })"
             class="btn btn-primary btn-block btn-sm  mr-2"
         >
           Edit this product
         </button>
       </div>
       <div class="col-md-12 mt-2">
         <button
             v-if="
                    ($guards.is_super_admin() || $guards.is_Admin()) && !product.suspended
                  "
             type="button"
             class="btn btn-warning btn-sm btn-block w-100"
             data-toggle="modal"
             data-target="#suspendModal"
         >
           Suspend Product
         </button>
       </div>
       <div class="col-md-12 mt-2">
         <button class="btn btn-danger btn-sm btn-block w-100" @click="trigger_del()" >Delete</button>
       </div>
     </div>
   </div>
 </div>

 <div class="card shadow-lg w-100 border-0 mb-0 mt-4" v-if="product.suspended">
    <div class="card-body px-lg-5 py-lg-3">
      <div class="row">
        <div class="col-md-12">
          <h3>Suspended for below reason</h3>
          <hr class="my-0 mb-3" />
          <p class="bg-warning p-3">
            {{ product.suspend_reason }}
          </p>
          <button
              class="mt-4 btn btn-default"
              @click="approve_from_supension"
              v-if="$guards.is_super_admin() || $guards.is_Admin()"
          >
            <em class="ni ni-check-bold"></em> Un-Suspend
          </button>
        </div>
      </div>
    </div>
 </div>


  <!-- Modal  For Suspention Reason-->
  <div
      class="modal fade"
      id="suspendModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="suspendModalLabel"
      aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Suspense Reason</h5>
          <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @close="closeModal"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body bg-secondary">
          <div class=" col-md-12 text-danger">
            <strong>After suspending this product it will no longer show on home page</strong>
            <hr/>
          </div>
          <div class="form-group col-md-12">
            <label class="form-control-label"
            >Please Add the reason to suspend this product</label
            >
            <textarea
                class="form-control form-control-alternative"
                placeholder="Reason for suspention"
                rows="5"
                v-model="suspend_reason.value"
            ></textarea>
            <label
                v-for="error in suspend_reason.errors"
                :key="error"
                class="form-control-flush text-danger text-sm"
            >{{ error }}</label
            >
          </div>
        </div>
        <div class="modal-footer">
          <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              id="cancelbtn"
          >
            Cancel
          </button>
          <button
              type="button"
              class="btn btn-warning"
              @click="suspend_product"
          >
            Suspend
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- end suspenction reason -->

</template>

<script>
import axios from "axios";
import {alert} from "@/utils/alertUtils";

export default {
  name: "ProductAdminActionSection",
  props:{
    product:{
      type:Object,
      default:()=>{}
    },
  },
  data(){
    return{
      suspend_reason: {
        value: "",
        errors: [],
      },
    }
  },
  methods:{
    changeStatusFn:function(){
      var data={
        product_uuid:this.product.uuid,
        status:this.product.is_special?"NOT_SPECIAL":"SPECIAL"
      }
      var msg=this.product.is_special?"Product is set as normal product":"Product is set as special product"

      axios
          .put(
              `${process.env.VUE_APP_BACKEND_URL}/api/products/special_status/change/`,
              data,
              {
                headers: {
                  Authorization: "Bearer " + this.$store.getters.getToken,
                },
              }
          )
          .then(() => {
            this.$emit("change");
            alert.success_center(
                msg
            );
          })
          .catch(() => {

            alert.error("Something went wrong! try again.");
            this.$emit("change");
          });
    },
    trigger_del:function(){
      alert.promptDelete(this.delete_product,this.product.uuid)
    },
    delete_product:function(uuid){
      axios
          .delete(
              `${process.env.VUE_APP_BACKEND_URL}/api/products/${uuid}`,
              {
                headers: {
                  Authorization: `Bearer ${this.$store.getters.getToken}`,
                },
              })
          .then(() => {
            alert.success("Product is deleted");
            this.$router.push({name:'Products'})
          })
          .catch((err) => {
            if(err.response.status===403 || err.response.status===401){
              alert.error("Session Expired, Please Login again");
              this.$store.dispatch("logout");
            }
          })
    },
    changeFn: function (e) {
      axios
          .put(
              `${process.env.VUE_APP_BACKEND_URL}/api/products/${this.product.uuid}/change_visibility`,
              {
                status: e.target.checked,
              },
              {
                headers: {
                  Authorization: "Bearer " + this.$store.getters.getToken,
                },
              }
          )
          .then(() => {
            this.$emit("change");
            alert.success_center(
                e.target.checked
                    ? "This product was successfully published!"
                    : "The product was successfully removed from public"
            );
          })
          .catch(() => {
            alert.error("Something went wrong! try again.");
            this.$emit("change");
          });
    },

    suspend_product: function () {
      if (this.suspend_reason.value.length == 0) {
        this.suspend_reason.errors = ["This field can't be empty"];
      } else {
        axios
            .put(
                `${process.env.VUE_APP_BACKEND_URL}/api/products/suspend_status/change/`,
                { reason: this.suspend_reason.value,product_uuid:this.product.uuid,status:'SUSPEND' },
                {
                  headers: {
                    Authorization: `Bearer ${this.$store.getters.getToken}`,
                  },
                }
            )
            .then(() => {
              alert.success_center("The Product has been suspended successfully");
              this.$emit("change");
              document.getElementById("cancelbtn").click();
            })
            .catch((err) => {
              if(err.response.status===403 || err.response.status===401){
                alert.error("Session Expired, Please Login again");
                 this.$store.dispatch("logout");
              }else{
                alert.error(
                    "An error occured please try again,if the error persist kindly contact the owner of this site!"
                );
              }
            });
      }
    },
    approve_from_supension:function(){
          axios
          .put(
              `${process.env.VUE_APP_BACKEND_URL}/api/products/suspend_status/change/`,
              {product_uuid:this.product.uuid,status:'UNSUSPEND' },
              {
                headers: {
                  Authorization: `Bearer ${this.$store.getters.getToken}`,
                },
              }
          )
          .then(() => {
            alert.success_center("The Product is removed from suspension successfully");
            this.$emit("change");
          })
          .catch((err) => {
            if(err.response.status===403 || err.response.status===401){
              alert.error("Session Expired, Please Login again");
              this.$store.dispatch("logout");
            }else{
              alert.error(
                  "An error occured please try again,if the error persist kindly contact the owner of this site!"
              );
            }
          });
    },
  }
}
</script>

<style scoped>

</style>