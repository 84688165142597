<template>
 <div>
   <ul class="nav nav-pills flex-column flex-sm-row">
     <li
         v-for="item in tab_items_slots"
         :key="item.props.id"
         :data-target="item.props.id"
         @click="change_tab"
         :class="(item.props.active_tab===item.props.id)?'tab-item-header tab-header-active':'tab-item-header'"
     >
       {{item.props.title}}
     </li>
   </ul>
   <div class="tab-content">
     <slot name="tab-containers">
     </slot>
   </div>
 </div>
</template>

<script>
export default {
  name: "TabContainer",
  props:['tab_name','id'],
  data(){
    return{
      tab_items_slots:[],
    }
  },
  methods:{
    change_tab:function (evt) {
      this.$emit('on-tab-change',evt.target.getAttribute('data-target'))
      this.change_active_tab_header(evt)
      this.$route.query[this.tab_name]=evt.target.getAttribute('data-target')

      //${this.tab_name}-tab` so the query value does not be the same as props value
      const query={[`${this.tab_name}-tab`]: evt.target.getAttribute('data-target')}
      this.$router.push({query: query });
    },
    change_active_tab_header(evt){
      var elems=document.getElementsByClassName('tab-item-header')
      Array.prototype.forEach.call(elems,function (el) {
        el.classList.remove('tab-header-active')
      })
      evt.target.classList.add('tab-header-active')
    },
    get_slot:function () {
      this.tab_items_slots=this.$slots["tab-containers"]()
    }
  },
  created() {
    this.get_slot()
  }
}
</script>

<style scoped>

</style>