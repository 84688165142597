<template>
 <div>
   <div class="col-md-12 d-flex justify-content-end">
     <button class="btn btn-sm btn-default" :disabled="res_data.previous==null" @click="trigger_search(res_data.previous)" >Previous</button>
     <button class="btn btn-sm btn-white" disabled="true">{{res_data.results?.length}}/{{res_data.count}}</button>
     <button class="btn btn-sm btn-default" :disabled="res_data.next==null" @click="trigger_search(res_data.next)">Next</button>
   </div>
 </div>
</template>

<script>
import axios from "axios";
import {alert} from "@/utils/alertUtils";

export default {
  name: "PaginatedSearch",
  props:{
    search_params: Array,
    url:{
      type:String,
      required:true
    },
  },
  data(){
    return{
      res_data:{},
      page:1,
      search_url:''

    }
  },
  watch:{
    search_params: {
      // eslint-disable-next-line no-unused-vars
      handler:function (val,oldVAlue) {
        this.search(`${this.url}?${this.get_params()}`)
      },
      deep:true
    }
  },
  methods:{
    trigger_search:function (url) {
      this.search(url)
    },
    search:function (url) {
      axios
          .get(
              url,
              {
                headers: {
                  Authorization: `Bearer ${this.$store.getters.getToken}`,
                },
              }
          )
          .then((res) => {
            this.res_data=res.data
            this.$emit('on-search',res.data.results)
          })
          .catch((err) => {
            if(err.response.status===403 || err.response.status===401){
              alert.error("Session Expired, Please Login again");
              this.$store.dispatch("logout");
            }
          });
    },

    get_params:function () {
      let param_string=`page=${this.page}`
      this.search_params.forEach((val) => {
        if (val.value?.length>0){
          param_string=param_string+`&${val.name}=${val.value}`
        }
      })
      return param_string
    }

  },
  created() {
   this.search(`${this.url}?${this.get_params()}`)
  }
}
</script>

<style scoped>

</style>