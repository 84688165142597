<template>
  <div>
    <button @click="$router.go(-1)" class="btn btn-dark btn-sm mt-4 mb-1">
      Back
    </button>
    <div class="w-100 row">
      <div class="col-md-8">
        <product-details
            @change="fetch_product"
            :product="product"
        ></product-details>
      </div>
      <div class="col-md-4">
        <ProductAdminActionSection @change="fetch_product" :product="product"/>
      </div>
    </div>
    <div class="row"  v-if="$guards.is_super_admin() || $guards.is_Admin()">
      <div class="col-md-12">
        <TabContainer @on-tab-change="onTabChange" tab_name="product-manager" id="product-manager">
          <template v-slot:tab-containers>
            <TabItem :active_tab="active_tab" id="manage_images" title="Manage Images">
              <div class="mt-4 card shadow w-100 p-4" id="image-section" v-if="product.galleries.length > 0">
                <hr/>
                <h4 class="mb-4 ">Already Existing Images</h4>
                <div class="row">
                  <div class="col-md-2" v-for="gal in product.galleries" :key="gal.uuid">
                    <div class="card">
                      <img
                          class="card-img-top"
                          style="height: 150px"
                          :src="root_img_url + gal.file"
                          alt="Card image cap"
                      />
                      <div class="card-body">
                        <div class="d-flex">
                          <button
                              class="btn btn-primary btn-sm"
                              @click="make_primary(gal.uuid)"
                              v-if="!gal.is_primary && !$guards.is_super_admin()"
                          >
                            Make Primary
                          </button>
                          <button
                              class="btn btn-danger btn-sm"
                              @click="remove_gallery(gal.uuid)"
                          >
                            <em class="fas fa-trash"></em>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabItem >
            <TabItem :active_tab="active_tab" id="manage_reviews" title="Manage reviews" >
              <div class="row">
                 <AdminReviewManager :product_id="product.uuid" />
              </div>
            </TabItem >
          </template>
        </TabContainer>
      </div>
    </div>
    <div class="mt-4 card shadow w-100 p-4" id="image-section" v-if="product.galleries.length > 0 && !$guards.is_super_admin() && !$guards.is_Admin()">
     <hr/>
      <h4 class="mb-4 ">Already Existing Images</h4>
      <div class="row">
        <div class="col-md-2" v-for="gal in product.galleries" :key="gal.uuid">
          <div class="card">
            <img
              class="card-img-top"
              style="height: 150px"
              :src="root_img_url + gal.file"
              alt="Card image cap"
            />
            <div class="card-body">
              <div class="d-flex">
                <button
                  class="btn btn-primary btn-sm"
                  @click="make_primary(gal.uuid)"
                  v-if="!gal.is_primary && !$guards.is_super_admin()"
                >
                  Make Primary
                </button>
                <button
                  class="btn btn-danger btn-sm"
                  @click="remove_gallery(gal.uuid)"
                >
                  <em class="fas fa-trash"></em>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4 card shadow w-100 p-4" v-if="!$guards.is_super_admin()">
      <h4 class="text-right mb-4">Add pictures to your product</h4>
      <drop-zone :options="options" @change="fetch_product"></drop-zone>
    </div>
   
   
  </div>
</template>

<script>
import Dropzone from "@/components/DropzoneComp";
import axios from "axios";
import { alert } from "../../utils/alertUtils";
import ProductDetails from "@/components/BasicProductDetails";
import TabContainer from "@/components/Tab/TabContainer";
import TabItem from "@/components/Tab/TabItem";
import AdminReviewManager from "@/views/Products/Components/AdminProdutReviewManager";
import ProductAdminActionSection from "@/views/Products/Components/ProductAdminActionSection.vue";
export default {
  name: "ProductGallery",
  components: {
    ProductAdminActionSection,
    AdminReviewManager,
    TabItem,
    TabContainer,
    "drop-zone": Dropzone,
    "product-details": ProductDetails,
  },
  data() {
    return {
      active_tab: this.$route.query.manager_tab? this.$route.query.manager_tab:"manage_images",
      backend_url: process.env.VUE_APP_BACKEND_URL,
      product: {
        galleries: [],
      },
      root_img_url:process.env.VUE_APP_IMG_URL,
      show_ranges: false,
      pricerange_post_data: {},
      pricerange_errors: {},
      price_ranges: [],
      options: {
        id: "dropzone_gallery",
        headers: {
          Authorization: `Bearer ${this.$store.getters.getToken}`,
        },
        upload_url: `${process.env.VUE_APP_BACKEND_URL}/api/products/${this.$route.params.uuid}/gallery`,
        maxFilesize: 15,
        maxFiles: 5,
        on_sending: function (file, xhr, form_data) {
          form_data.append("is_primary", false);
          form_data.append("image", file);
          form_data.append(
            "product_id",
            `${this.options.the.$route.params.uuid}`
          );
        },
        on_remove: function (file) {
          if (file.status === "success") {
            axios
              .delete(
                `${process.env.VUE_APP_BACKEND_URL}/api/galleries/${file.response.uuid}/image/removal`,
                {
                  headers: {
                    Authorization: `Bearer ${this.options.the.$store.getters.getToken}`,
                  },
                }
              )
              .then(() => {
                this.options.the.$emit("change");
              })
              .catch((err) => {
                if(err.response.status===403 || err.response.status===401){
                  alert.error("Session Expired, Please Login again");
                  this.$store.dispatch("logout");
                }
              });
          }
        },
        on_complete: function (file) {
          if (file.status === "success") {
            file.response = JSON.parse(file.xhr.response);
            this.options.the.$emit("change");
          }
        },
      },


    };
  },
  methods: {
    onTabChange:function (evt) {
      this.active_tab=evt
    },
    fetch_product: function () {
      axios
        .get(
          `${process.env.VUE_APP_BACKEND_URL}/api/products/${this.$route.params.uuid}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then((res) => {
          
          this.product = res.data;
        })
        .catch((err) => {
          if(err.response.status===403 || err.response.status===401){
              alert.error("Session Expired, Please Login again");
              this.$store.dispatch("logout");
            }
        });
    },
    remove_gallery: function (uuid) {
      axios
        .delete(
          `${process.env.VUE_APP_BACKEND_URL}/api/galleries/${uuid}/image/removal`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then(() => {
       
          alert.success_center("Image removed");
          this.fetch_product();
        })
        .catch((err) => {
          if(err.response.status===403 || err.response.status===401){
              alert.error("Session Expired, Please Login again");
              this.$store.dispatch("logout");
            }
        });
    },
    make_primary: function (uuid) {
      axios
        .put(
          `${this.backend_url}/api/products/${this.$route.params.uuid}/galleries/${uuid}/image/makeprimary`,
          {},
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then(() => {
          alert.success("Primary Image set successfully");
          this.fetch_product();
        })
        .catch((err) => {
          if(err.response.status===403 || err.response.status===401){
              alert.error("Session Expired, Please Login again");
              this.$store.dispatch("logout");
            }
        });
    }, 
    
    alert_delivery_info: function () {
      let info =
        '<h3>What is a Delivery Price Range?</h3><p class="text-justify"> <span class="font-weight-bold"> Delivery Price Range </span> is a way you set the price for delivery accoring to the quantity of the product user has selected, you also provide that price range in different location .</p><h4 class="text-left">Example:</h4> <p class="text-justify">If We take an example of <span class="font-weight-bold">a basket</span>, range might be minimun range 1 and maximum range 5 price 3000 in kigali. this means from 1 to 5 baskets you deliver it on 3000 in kigali.</p><p class="text-justify font-weight-bold"><em class="fas fa-exclamation-triangle fa-2x"></em> If you do not specify the price range, we consider as you make a free delivery</p>';
      alert.info_center_html(info);
    },
    
  },
  created() {
    this.fetch_product();
  },
};
</script>

<style>
</style>