<template>
 <div :id="id" v-if="id===active_tab">
   <slot></slot>
 </div>
</template>
<script>
export default {
  name: "TabItem",
  props:['id','active_tab','title'],
}
</script>
<style scoped>
</style>